@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-primary mat-option.mat-mdc-option {
    color: var(--text);
    font-size: $font-size;
    min-height: $base-height;

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: inherit;
    }

    &.root-only {
      @include root-only();
    }

    mat-icon,
    i {
      color: var(--icon-hue);
      margin-right: $base-padding;
    }
  }

  mat-option.mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mat-primary .mat-mdc-option.mat-mdc-option-multiple {
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      background-color: var(--primary-700);
    }

    .mat-pseudo-checkbox {
      margin-right: $base-padding;

      &::after {
        color: #fff;
      }
    }
  }

  // Need it when a "Select All" mat-checkbox is added to a multi select
  .mat-mdc-select-panel .mdc-form-field > label {
    padding-left: 0;
  }
}

@mixin mat-toolbar-select($background-color, $text-color) {
  background-color: $background-color;
  border-radius: 3px;
  margin: 0 $base-padding * 0.5;

  ::ng-deep {
    .mat-mdc-select-arrow {
      color: $text-color;
      margin-right: $base-padding;
    }

    .mat-mdc-select-trigger {
      height: $base-height - $base-padding;
      padding-left: $base-padding;
    }

    .mat-mdc-select-value,
    span {
      color: $text-color;
    }
  }

  &.mat-mdc-select-disabled ::ng-deep {
    .mat-mdc-select-arrow,
    .mat-mdc-select-value,
    span {
      color: var(--text-disabled);
    }
  }
}
