@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-form-field {
    width: 100%;
    /* We need a minimum because of the mat-select, since the mat-option elements may become very small (e.g. schematic
    details sidebar), since the they have the same width as mat-select. */
    min-width: 150px;
  }

  .mat-mdc-form-field {
    .mat-mdc-floating-label {
      overflow: visible;
    }

    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
      content: "＊";
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
    }

    &:not(.ng-touched):not(.ng-valid):not(.mat-form-field-disabled)
      .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
      color: var(--orange);
      font-weight: bold;
      transform: scale(1.2);
      animation: avelon-continuous-rotate 10s infinite;
      animation-timing-function: linear;
    }
  }

  .mat-mdc-form-field.root-only .mat-mdc-text-field-wrapper {
    @include root-only();
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-icon-suffix {
    color: var(--icon-hue);
  }

  .mat-mdc-form-field-text-suffix,
  .mat-mdc-form-field-text-prefix {
    white-space: nowrap;
  }

  .mat-mdc-form-field-icon-prefix {
    padding-left: $base-padding;
  }

  .mat-mdc-form-field.mat-form-field-disabled {
    .mat-mdc-form-field-infix,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix {
      color: var(--text-disabled);

      .mdc-icon-button:not([disabled]) {
        color: var(--icon-hue);
      }
    }
  }

  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    &:not(.mat-form-field-invalid) mat-label {
      color: var(--label-text);
    }
  }

  mat-form-field:not(.mat-form-field-invalid) .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--label-text);
  }

  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: var(--text);
  }

  // This is needed for long error messages that overflow over other elements
  .mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    &::before {
      content: none;
    }

    .mat-mdc-form-field-error-wrapper {
      position: relative;
    }
  }
}

.avelon-theme.avelon-dark-theme {
  .mat-mdc-form-field:not(.ng-touched):not(.ng-valid):not(.mat-form-field-disabled)
    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    // Adjust for better contrast on dark theme.
    color: var(--yellow);
  }
}
