@import "scss/root-only/root-only.mixin";

mat-expansion-panel.root-only {
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    @include root-only();
  }
}

.no-content-padding {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
