@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-autocomplete-panel {
    .mat-mdc-option {
      color: var(--text);
      font-size: $font-size;
      min-height: $base-height;

      .mdc-list-item__primary-text {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
